<template>
  <div>
    <template v-if="$store.getters['app/isTestEnded']">
      <TestEnded/>
    </template>
    <template v-else>
      <div role="main" style="height: calc(100vh - 105px)" id="highlightable-content">
        <div class="itemBody activeItem">
          <div class="container">
            <div class="rubricBlock" view="candidate">
              <p>
                <strong>
                  <span author-class="fs14pt">Part {{ part[display_quest.part_no - 1].no }}</span>
                </strong>
                <br/>
                <br/>
                {{ part[display_quest.part_no - 1].part_note }}
              </p>
            </div>
          </div>
          <div class="container scroll-content" id="mainContent">
            <div class="scroll-container" style="height: calc(100vh - 190px)">
              <keep-alive>
                <component
                    :is="part[display_quest.part_no - 1].type"
                    :part="part[display_quest.part_no - 1]"
                    :focus-quest-no="focusQuestNo"
                    :questions="questions.filter(q => q.part_no === part[display_quest.part_no - 1].no)"/>
              </keep-alive>
            </div>
          </div>
        </div>
        <audio id="sample-audio-content" loop>
          <source src="@assets/sound/sample-audio.ogg" type="audio/ogg">
          Your browser does not support the audio element.
        </audio>
      </div>
      <Navigation
          :focus-quest-no="focusQuestNo"
          :questions="questions"/>
      <right-click/>
    </template>
  </div>
</template>

<script>
import {bus} from "@/main";
import TestEnded from "@/views/components/global/TestEnded";
import OneChoice from "@/views/apps/academic-listening/components/OneChoice";
import Navigation from "@/views/components/global/navigation";
import MultipleChoice from "@/views/apps/academic-listening/components/MultipleChoice";
import Matching from "@/views/apps/academic-listening/components/Matching";
import PMDLabelling from "@/views/apps/academic-listening/components/PMDLabelling";
import Completion from "@/views/apps/academic-listening/components/Completion";
import RightClick from "@/views/components/global/RightClick";

export default {
  name: "ListeningMain",
  components: {RightClick, Completion, PMDLabelling, Matching, MultipleChoice, Navigation, OneChoice, TestEnded},
  data() {
    return {
      focusQuestNo: 1,
      part: [
        {
          no: 1,
          type: "one-choice",
          part_note: "Read the texts and answer questions 1 – 3.",
          part_content: "",
          note: "<strong>Questions 1</strong> – <strong>3<br/></strong>Choose the correct answer. <br/>",
          questions: [
            {
              id: 1,
              content: "Why did Judy choose to study the East End of London?",
              choice: [
                {
                  id: 1,
                  label: 'She wanted to understand her own background.'
                },
                {
                  id: 2,
                  label: 'She was interested in place names.'
                },
                {
                  id: 3,
                  label: 'She had read several books about it.'
                },
              ],
              answer: ''
            },
            {
              id: 2,
              content: "What was Judy’s main source of materials?",
              choice: [
                {
                  id: 1,
                  label: 'books'
                },
                {
                  id: 2,
                  label: 'newspapers'
                },
                {
                  id: 3,
                  label: 'interviews'
                },
              ],
              answer: ''
            },
            {
              id: 3,
              content: "What difficulty did Judy have with her dissertation?",
              choice: [
                {
                  id: 1,
                  label: 'writing the first draft'
                },
                {
                  id: 2,
                  label: 'organising what she had collected'
                },
                {
                  id: 3,
                  label: 'finding enough relevant sources'
                },
              ],
              answer: ''
            },
          ]
        },
        {
          no: 2,
          type: "multiple-choice",
          part_note: "Read the texts and answer questions 4 – 6.",
          part_content: "",
          note: "<strong>Questions 4</strong> – <strong>6<br/></strong>Choose <strong>THREE</strong> correct answer. <br/>",
          questions: [
            {
              id: 4,
              content: "Which THREE things does the Guide to the Library have information about?",
              choice: [
                {
                  id: 1,
                  label: 'book reservations'
                },
                {
                  id: 2,
                  label: 'reading room rules'
                },
                {
                  id: 3,
                  label: 'catalogue'
                },
                {
                  id: 4,
                  label: 'disabled facilities'
                },
                {
                  id: 5,
                  label: 'location of rooms'
                },
                {
                  id: 6,
                  label: 'cafe'
                },
                {
                  id: 7,
                  label: 'regular events'
                },
                {
                  id: 8,
                  label: 'staff'
                },
              ],
              answer: ''
            },
            {
              id: 5,
              content: "",
              answer: ''
            },
            {
              id: 6,
              content: "",
              answer: ''
            },
          ]
        },
        {
          no: 3,
          type: "matching",
          part_note: "Read the texts and answer questions 7 – 11.",
          part_content: "",
          note: "<strong>Questions 7</strong> – <strong>11<br/></strong>Who is responsible for each area? Choose the correct answer and move it into the gap. <br/>",
          questions: [
            {
              id: 7,
              content: "<p><strong><span author-class=\"fs11pt\"><span\n" +
                  "      author-class=\"fs12pt\">People</span></span></strong><br/><br/>Mary Brown     ###INPUT###  <br/><br/>John Stevens  \n" +
                  "    ###INPUT### <br/><br/>Alison Jones    ###INPUT###  <br/><br/>Tim Smith       ###INPUT### <br/><br/>Jenny James  \n" +
                  "    ###INPUT### </p>",
              choice: [
                {
                  id: 1,
                  label: 'Finance'
                },
                {
                  id: 2,
                  label: 'Food'
                },
                {
                  id: 3,
                  label: 'Health'
                },
                {
                  id: 4,
                  label: 'Kids\' Counselling'
                },
                {
                  id: 5,
                  label: 'Organisation'
                },
                {
                  id: 6,
                  label: 'Rooms'
                },
                {
                  id: 7,
                  label: 'Sport'
                },
                {
                  id: 8,
                  label: 'Trips'
                },
              ],
              answer: ''
            },
            {
              id: 8,
              content: "",
              answer: ''
            },
            {
              id: 9,
              content: "",
              answer: ''
            },
            {
              id: 10,
              content: "",
              answer: ''
            },
            {
              id: 11,
              content: "",
              answer: ''
            },
          ]
        },
        {
          no: 4,
          type: "p-m-d-labelling",
          part_note: "Read the texts and answer questions 12 – 14.",
          part_content: "<span author-class=\"inlineImage\" author-id=\"img-1457702407\">\n" +
              "        <img alt=\"\"\n" +
              "             height=\"298\"\n" +
              "             src=\"https://s3-eu-west-1.amazonaws.com/oep2stt/sample-listening-plan-map-diagram-labelling/test-content/UILI20003L8-0-0-21.PNG\"\n" +
              "             width=\"500\"/></span>",
          note: "<strong>Questions 12</strong> – <strong>14<br/></strong>The map has eight labels (<strong>A – H</strong>). Choose the correct label for each building.<br/>",
          questions: [
            {
              id: 12,
              content: "Quilt Shop",
              answer: ''
            },
            {
              id: 13,
              content: "Handicrafts Museum",
              answer: ''
            },
            {
              id: 14,
              content: "School House",
              answer: ''
            }
          ]
        },
        {
          no: 5,
          type: "completion",
          part_note: "Read the texts and answer questions 15 – 21.",
          part_content: "",
          note: "<strong>Questions 15</strong> – <strong>21<br/></strong>Complete the notes. Write <strong>ONE WORD AND/OR A NUMBER</strong> in each gap.<br/>",
          questions: [
            {
              id: 15,
              content: "<table author-class=\"theme-border-4\">\n" +
                  "      <thead></thead>\n" +
                  "      <tbody>\n" +
                  "      <tr>\n" +
                  "        <td colspan=\"2\"><p><span\n" +
                  "            author-class=\"fs12pt\"><strong>Phone call about second-hand furniture</strong><strong><br/></strong></span>\n" +
                  "        </p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td></td>\n" +
                  "        <td> </td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td><p><strong>Items</strong></p></td>\n" +
                  "        <td></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td><p>Dining table</p></td>\n" +
                  "        <td><p>- ###INPUT### shape</p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td></td>\n" +
                  "        <td><p>- medium size</p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td></td>\n" +
                  "        <td><p>-###INPUT### old</p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td></td>\n" +
                  "        <td><p>- £25.00</p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td></td>\n" +
                  "        <td><p> <br/><br/></p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td><p>Dining chairs</p></td>\n" +
                  "        <td><p>- set of ###INPUT### chairs</p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td></td>\n" +
                  "        <td><p>- seats covered in ###INPUT### material</p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td></td>\n" +
                  "        <td><p>- in ###INPUT### condition</p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td></td>\n" +
                  "        <td><p>- £20.00</p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td></td>\n" +
                  "        <td><p><br/> </p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td><p>Desk</p></td>\n" +
                  "        <td><p>- length: ###INPUT### </p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td></td>\n" +
                  "        <td><p>- 3 drawers. Top drawer has a ###INPUT###</p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td></td>\n" +
                  "        <td><p>- £50.00</p></td>\n" +
                  "      </tr>\n" +
                  "      <tr>\n" +
                  "        <td></td>\n" +
                  "        <td> </td>\n" +
                  "      </tr>\n" +
                  "      </tbody>\n" +
                  "    </table>",
              answer: ''
            },
            {
              id: 16,
              content: "",
              answer: ''
            },
            {
              id: 17,
              content: "",
              answer: ''
            },
            {
              id: 18,
              content: "",
              answer: ''
            },
            {
              id: 19,
              content: "",
              answer: ''
            },
            {
              id: 20,
              content: "",
              answer: ''
            },
            {
              id: 21,
              content: "",
              answer: ''
            },
          ]
        },
        {
          no: 6,
          type: "completion",
          part_note: "Read the texts and answer questions 22 – 24.",
          part_content: "",
          note: "<p><strong>Questions </strong><strong>1</strong> – <strong>3<br/></strong>Answer\n" +
              "        the questions. Write <strong>ONE WORD ONLY</strong> in each gap.<br/><br/>List <strong>THREE</strong> types of\n" +
              "        staff that Pacific Clothing want to recruit at present.<br/><br/></p>",
          questions: [
            {
              id: 22,
              content: "<ul>\n" +
                  "      <li>\n" +
                  "        <p> ###INPUT###  <br/><br/></p>\n" +
                  "      </li>\n" +
                  "      <li>\n" +
                  "        <p>###INPUT###  <br/><br/></p>\n" +
                  "      </li>\n" +
                  "      <li><p>###INPUT### </p></li>\n" +
                  "    </ul>",
              answer: ''
            },
            {
              id: 23,
              content: "",
              answer: ''
            },
            {
              id: 24,
              content: "",
              answer: ''
            }
          ]
        },

      ],
      display_quest: {
        type: '',
        part_no: 1
      },
      questions: [],
    }
  },
  created() {
    this.init()
    this.$nextTick(() => {
      bus.$emit('startCountdown')
      bus.$on('changeQuest', this.changeQuest)
      const ele = document.getElementById('sample-audio-content')
      ele.volume = this.$store.getters["app/volume"]
      ele.play()
    })
  },
  methods: {
    changeQuest(no) {
      this.focusQuestNo = no
      this.display_quest = this.questions[no - 1]
    },
    init() {
      this.part.forEach(p => {
        p.questions.map(q => q.part_no = p.no)
      })
      this.questions = this.part.map(p => p.questions).flat()
      this.display_quest = this.questions[0]
    },

  },
}
</script>

<style scoped>

</style>
