<template>
  <div author-class="container twoColChoice" class="clearfix">
    <p v-html="part.part_note"></p>
    <div
        :id="questions[0].id"
        @click="changeQuest(questions[0].id)"
        @focus="changeQuest(questions[0].id)"
        :class="{'inFocus' : focusQuestNo === questions[0].id}"
        class="choiceInteraction blockInteraction interaction"
        style="margin-top: 0; margin-bottom: 0;">
      <span class="questionNumber"> {{ questions[0].id }} - {{ questions[questions.length - 1].id }}</span>
      <p class="prompt"> {{ questions[0].content }} </p>
      <ul shuffle="false">
        <template v-for="c in questions[0].choice">
          <li :key="c.id" class="simpleChoice choice">
            <label>
              <input v-model="selectedChoice" type="checkbox" :value="c.id" :disabled="selectedChoice.length >= questions.length && selectedChoice.indexOf(c.id) === -1"/>
              <p>{{ c.label }}</p>
            </label>
          </li>
        </template>
      </ul>
    </div>

  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
  name: "MultipleChoice",
  mixins: [common],
  props: {
    part: {
      type: Object,
      default() {
        return {
          note: "",
        }
      }
    },
    questions: {
      type: Array,
      default() {
        return []
      }
    },
    focusQuestNo: {
      type: Number,
      default: 1
    }
  },
  watch: {
    selectedChoice () {
      this.questions.forEach((q,ind) => {
        q.answer = this.selectedChoice[ind] ? this.selectedChoice[ind].toString() : ''
      })
    }
  },
  data () {
    return {
      selectedChoice: []
    }
  },
  methods: {
    hover(id) {
      const element = document.getElementById(id)
      if (element) {
        let clazz = element.className
        if (!clazz.includes("ui-state-hover")) element.className += " ui-state-hover"
      }
    },
    out(id) {
      const element = document.getElementById(id)
      if (element) {
        let clazz = element.className
        clazz = clazz.replace(" ui-state-hover", "")
        element.className = clazz
      }
    },
  }
}
</script>

<style>
h3 + div[role="tabpanel"] {
  -webkit-transition: max-height .3s;
  -moz-transition: max-height .3s;
  -ms-transition: max-height .3s;
  -o-transition: max-height .3s;
  transition: max-height .3s;
  max-height: 0;
}

h3.inFocus + div[role="tabpanel"] {
  -webkit-transition: max-height .7s;
  -moz-transition: max-height .7s;
  -ms-transition: max-height .7s;
  -o-transition: max-height .7s;
  transition: max-height .7s;
  max-height: 200px;
}
</style>
