<template>
  <div author-class="container twoColChoice" class="clearfix">
    <p v-html="part.part_note"> </p>
    <template v-for="q in questions">
      <div
          :id="q.id"
          @click="changeQuest(q.id)"
          @focus="changeQuest(q.id)"
          :class="{'inFocus' : focusQuestNo === q.id}"
          class="choiceInteraction blockInteraction interaction"
          style="margin-top: 0; margin-bottom: 0;">
        <span class="questionNumber"> {{ q.id }} </span>
        <p class="prompt"> {{q.content}} </p>
        <ul shuffle="false">
          <template v-for="c in q.choice">
            <li :key="c.id" class="simpleChoice choice">
              <label>
                <input type="radio" v-model="q.answer" :value="c.label"/>
                <p>{{ c.label }}</p>
              </label>
            </li>
          </template>
        </ul>
      </div>
    </template>

  </div>
</template>

<script>
import common from "@/mixins/common";
export default {
  name: "OneChoice",
  mixins: [common],
  props: {
    part: {
      type: Object,
      default() {
        return {
          note: "",
        }
      }
    },
    questions: {
      type: Array,
      default() {
        return []
      }
    },
    focusQuestNo: {
      type: Number,
      default: 1
    }
  },
  methods: {
    hover(id) {
      const element = document.getElementById(id)
      if (element) {
        let clazz = element.className
        if (!clazz.includes("ui-state-hover")) element.className += " ui-state-hover"
      }
    },
    out(id) {
      const element = document.getElementById(id)
      if (element) {
        let clazz = element.className
        clazz = clazz.replace(" ui-state-hover", "")
        element.className = clazz
      }
    },
  }
}
</script>

<style>
h3 + div[role="tabpanel"] {
  -webkit-transition: max-height .3s;
  -moz-transition: max-height .3s;
  -ms-transition: max-height .3s;
  -o-transition: max-height .3s;
  transition: max-height .3s;
  max-height: 0;
}

h3.inFocus + div[role="tabpanel"] {
  -webkit-transition: max-height .7s;
  -moz-transition: max-height .7s;
  -ms-transition: max-height .7s;
  -o-transition: max-height .7s;
  transition: max-height .7s;
  max-height: 200px;
}
</style>
