<template>
  <div author-class="container generic">
    <p v-html="part.note"></p>
    <div author-class="container genericTwoColumn split-5050">
      <div author-class="left" v-html="part.part_content" />
      <div author-class="right">
        <div author-class="container generic">
          <div author-class="tableMultiEntry" class="tableMatchInteraction multi blockInteraction interaction" >
            <table style="width:95%;" v-if="questions.length">
              <thead>
              <tr>
                <th></th>
                <template v-for="choice in ['A','B','C','D','E','F','G','H']">
                  <th><p><strong>{{ choice }}</strong></p></th>
                </template>
              </tr>
              </thead>
              <tbody>
              <template v-for="q in questions">
                <tr style="height: 29px;" :key="q.id" @click="changeQuest(q.id)">
                  <td :class="{'inFocus' : focusQuestNo === q.id}">
                    <span class="questionNumber">{{ q.id }}</span>
                    <p>{{ q.content }}</p></td>
                  <template v-for="choice in ['A','B','C','D','E','F','G','H']">
                    <td tabindex="0" @focus="changeQuest(q.id)" @click="() => q.answer = choice"
                        :class="{'inFocus' : focusQuestNo === q.id, 'checked' : q.answer === choice}">
                      <input class="simpleAssociableChoice associableChoice choice" style="display:none" type="checkbox"/> 
                    </td>
                  </template>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixins/common";

export default {
  name: "PMDLabelling",
  mixins: [common],
  props: {
    part: {
      type: Object,
      default() {
        return {
          note: "",
        }
      }
    },
    questions: {
      type: Array,
      default() {
        return []
      }
    },
    focusQuestNo: {
      type: Number,
      default: 1
    }
  },
}
</script>

<style scoped>

</style>
